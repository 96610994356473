/**
 * @description
 * @author raju.c@pickcel.com
 * @since 16-mar-2018
 */

import { Injectable, Inject } from '@angular/core';
import { CookieService } from './cookie.service';
import { DOCUMENT } from '@angular/common';

import { AclService } from 'ng2-acl/dist';

import { Http } from '@angular/http';

@Injectable()
export class TokenService {
    constructor(
        private cookieService: CookieService,
        private aclService: AclService,
        @Inject(DOCUMENT) private document: any
    ) { }

    aclData = {};
    setUser(user, perms?) {
        this.cookieService.set('isLoggedIn', 'true', 1, `/`, `${this.document.domain}`);
        this.cookieService.set('userData', JSON.stringify(user), 1, `/`, `${this.document.domain}`);
        // console.log('PERMS', perms);
        // console.log('USER', user);
        this.aclData = {
            [perms.role]: []
        }

        // add: true
        // delete: true
        // icon: "https://d2qf6k8jfqd09k.cloudfront.net/assets/signage/icons/menu/display.svg"
        // id: "DISPLAY"
        // label: "Display"
        // route: "screen"
        // sub_menus: []
        // update: true
        // view: true

        perms.menus.forEach(value => {
            if (value.add) {
                this.aclData[perms.role].push(value.id.toUpperCase() + '_' + 'ADD');
            }
            if (value.update) {
                this.aclData[perms.role].push(value.id.toUpperCase() + '_' + 'UPDATE');
            }
            if (value.delete) {
                this.aclData[perms.role].push(value.id.toUpperCase() + '_' + 'DELETE');
            }
            if (value.view) {
                if (perms.role !== 'ADMIN') {
                    if (value.id === 'SETTINGS') {
                        //DONT PUSH
                    }
                    else {
                        this.aclData[perms.role].push(value.id.toUpperCase() + '_' + 'VIEW');
                    }
                } else {
                    this.aclData[perms.role].push(value.id.toUpperCase() + '_' + 'VIEW');
                }
            }
        });

        // console.log('this is acl data', this.aclData);
        this.aclService.setAbilities(this.aclData);
        // Attach the member role to the current user
        this.aclService.attachRole(perms.role);

        if (perms !== undefined && perms !== null) {

            if (perms.role !== 'ADMIN') {
                perms.menus = perms.menus.filter((data) => {
                    return data.id !== "SETTINGS";
                })
            }
            localStorage.setItem('perms', btoa(JSON.stringify(perms)));
            const initialRoute = perms.menus.find((itm) => itm.view === true);
            localStorage.setItem('initialRoute', btoa(JSON.stringify(`/index/${initialRoute.route}`)));
        }
        // console.log(this.document);
    }

    // stores country name , country code , currency code
    setCountryData(country) {
        this.cookieService.set('country', JSON.stringify(country), 1, `/`, `${this.document.domain}`);
    }

    setSubscriptionData(data) {
        this.cookieService.set('subscription', JSON.stringify(data), 1, `/`, `${this.document.domain}`);
    }

    getSubscriptionData() {
        const Subscription = this.cookieService.get('subscription');
        if (Subscription) {
            const subscription = Subscription;
            return subscription;
        } else {
            return '';
        }
    }

    getCountryData() {
        const Country = this.cookieService.get('country');
        if (Country) {
            const country = Country;
            return Country;
        } else {
            return '';
        }
    }

    getCurrentUserData() {
        const userData = this.cookieService.get('userData');
        if (userData) {
            const user = userData;
            return user;
        } else {
            return null;
        }
    }

    setToken(token: string) {
        //   alert('setToken');
        this.cookieService.set('accessToken', token, 1);
    }

    getCurrentUserToken(): string {
        return this.cookieService.get('accessToken');
    }

    removeToken(): void {
        this.cookieService.delete(' ');
        this.cookieService.delete('isLoggedIn');
        this.cookieService.delete('accessToken');

    }

    removeUserData(): void {
        this.cookieService.delete('userData');
    }

    removeCountryData(): void {
        this.cookieService.delete('country');
    }

    removeSubscriptionData(): void {
        this.cookieService.delete('subscription');
    }

    removeSalesIQ(){
        this.cookieService.delete('zoho-sales-iq');
    }

    isAllowedToAccess(ACTION, ID, SUB?): any {
        
            const menus = (JSON.parse(atob(localStorage.getItem('perms'))));
            const newArr = menus.menus.find((itm) => itm.id === ID);
            if(SUB) {
                let subArr = newArr.sub_menus.find((subItem) => subItem.id === SUB);
                if(subArr) {
                    return (subArr[ACTION] || menus.role == 'ADMIN');
                } else if(!subArr && menus.role == 'ADMIN'){
                    return true;
                } else {
                    return false;
                }
            } else {
                return newArr[ACTION];
            }
    }

    storeNotifications(messages) {
        this.cookieService.set('notifications', JSON.stringify(messages), 1);
    }

    getNotifications() {
        let messages = this.cookieService.get('notifications');
        if (messages) {
            return JSON.parse(messages);
        } else {
            return null;
        }
    }

    setSalesIQ(messages){
        this.cookieService.set('zoho-sales-iq', JSON.stringify(messages), 1);
    }

    getSalesIQ(){
        let messages = this.cookieService.get('zoho-sales-iq');
        if (messages) {
            return messages;
        } else {
            return null;
        }
    }
}

