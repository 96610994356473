/**
* @description You create notification service
* @since  10-07-2018
* @author uday.kumar@pickcel.com
*/

import { Observable } from 'rxjs';



import { Injectable, Inject, ViewContainerRef, NgZone } from '@angular/core';
import { Headers, Http, Response, HttpModule, URLSearchParams } from '@angular/http';
import * as socketIo from 'socket.io-client';
import { ErrorService } from '../services/error.service';
import { Config } from '../constants/config';


@Injectable()
export class NotificationService {
    config = new Config();
  private SERVER_URL;
  private socket;

  constructor(
    private http: Http,
    public _errorService: ErrorService
  ) {
    // this.socket = socketIo(this.config.URL);
    // this.socket = socketIo(this.config.URL + '/subscription/getLastCreatedSubscription');
  }

  onSubscriptionCreate(): Observable<any> {
    const observable = new Observable(observer => {
        this.socket.on('afterCreate', (data) => {
            console.log('observer ::', data);
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
    return observable;
  }
}
