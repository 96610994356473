/**
 * @description This Component handles all the validation errors of forms
 * @author Nizamuddin.shaik@pickcel.com
 * @since 17-Aug-2018
 */
// tslint:disable
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-field-validation',
  template: '',
})
export class FieldValidationComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }

  /**
   * @description To validate each field individually
   * @param data data coming from the subscription of value changes from each form
   *  initialization,contains object with all the from fields.
   * @param formGroup full form details with type 'formGroup'
   * @param formErrors initialization of each form fields with empty values (class,interface)
   * @param validationMessages The messages which need to be displayed after
   *  getting any of the error
   */
  validate(data: any, formGroup: FormGroup, formErrors, validationMessages): void {
    if (!formGroup) return;
    for (const field in formErrors) {
      formErrors[field] = '';
      const input = formGroup.get(field);
      if (input.invalid && input.dirty || input.invalid && input.touched) {
        for (const errors in input.errors) {
          formErrors[field] = validationMessages[field][errors];
        }
      } else {
        formErrors[field] = false;
      }
    }
  }

  /**
   * @description To validate all the from fields when submit button is pressed before the form becomes valid
   * @param formGroup full form details with type 'formGroup'
   * @param formErrors initialization of each form fields with empty values (class,interface)
   * @param validationMessages The messages which need to be displayed after
   *  getting any of the error
   */
  validateAllFormFields(formGroup: FormGroup, formErrors, validationMessages) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl || FormArray) {
        control.markAsTouched({ onlySelf: true });
        if (control.invalid) {
          for (const errors in control.errors) {
            formErrors[field] = validationMessages[field][errors];
          }
        } else {
          formErrors[field] = false;
        }
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control, formErrors, validationMessages);
      }
    });
  }

}
