<section class="login-register">
    <div class="card">
        <!-- <div class="top-area">
            <p>{{view == 'login' ? 'Not a member ?' : 'I have an account'}}</p>
            <button class="btn btn-outline-light" (click)="changeView(view)">{{view == 'login' ? 'Create new account' : 'Sign In'}}</button>
        </div> -->
        <div class="logo">
            <img [src]="logoUrl" width="153px" class="img-fluid" alt="logo">
        </div>
        <div class="text">
            <h1>{{ view == 'login' ? 'Log in' : 'Signup to use '+clientName}}</h1>
            <!-- <p>Sorry! Pickcel web console is not supported on mobile browser. </p> -->
            <p>{{clientName}} web console is not supported on mobile browser.</p>
        </div>
        <div class="download" *ngIf='showPickcelGo'>
            <h2>Download Pickcel Go App now</h2>
            <div class="download-btn">
                <a href="https://play.google.com/store/apps/details?id=digital.signage.software.editor" class="icon" target="_blank">
                    <img src="https://pickcel.com/assets/img/playstore.png" class="img-fluid" alt="">
                </a>
                <a href="https://apps.apple.com/in/app/pickcel-go/id1533647872" target="_blank" *ngIf="view == 'login'" class="icon">
                    <img src="https://pickcel.com/assets/img/apple-store.png" class="img-fluid" alt="">
                </a>
            </div>
        </div>
        <!-- <div class="or">
            <p>Or</p>
            <a class="btn btn-outline-primary btn-sm mx-auto"><img src="../../../../assets/img/mobile-browser/laptop.svg" alt="">Use Laptop
                Browser</a>
        </div> -->
    </div>
</section>