<!-- lOGIN SECTION STARTED -->
<div class='full-page' *ngIf='showLoader'>
  <div class="spinner">
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="length" fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
  </div>
</div>


<div class="login-section" *ngIf="!showLoader">
  <div class="form-wrapper">
    <div class="logo">
      <img [src]="loginEnvConfig.LOGO_SRC" class="img-fluid" alt="pickcel">
    </div>

    <div class="message" *ngIf="message != ''">
      <img src="../../assets/icons/mail-outline.svg" class="img-fluid" alt="mail">
      <p>{{message}}</p>
    </div>

    <div class="form">
      <h5 i18n>
        {loginEnvConfig.SIGNIN_TITLE, select, Log into Pickcel {Log In} other {{{loginEnvConfig.SIGNIN_TITLE}}} }
      </h5>
      <!-- ngNativeValidate -->
      <form action="#" [formGroup]="loginForm" (ngSubmit)="onLogin()" autocomplete="off" novalidate>
        <div class="form-group">
          <label for="exampleInputEmail1" i18n>Email</label>
          <input type="text" formControlName="email" class="form-control" id="exampleInputEmail1"
            aria-describedby="emailHelp" placeholder="Enter your email" i18n-placeholder="Email| searchbox placeholder" autofocus>
          <div class="valid-error" *ngIf="loginFormError.email">
            {{loginFormError.email}}
          </div>
        </div>
        <div class="form-group input-custom mb-0">
          <label for="exampleInputPassword1" i18n>Password</label>
          <input [type]="type" class="form-control" #password formControlName="password" id="exampleInputPassword1"
            placeholder="Enter your password" i18n-placeholder="Password| searchbox placeholder">
          <span class="input-icon">
            <em [ngClass]="(type == 'password') ? 'fa fa-eye-slash' : 'fa fa-eye'" class="custom-eye" aria-hidden="true"
              (click)="showPassword(password.type)"></em>
          </span>
          <div class="valid-error" *ngIf="loginFormError.password">
            {{loginFormError.password}}
          </div>
        </div>
        
        <!-- !loginForm.valid   -->
        <div *ngIf='loginEnvConfig.FORGOT_PASSWORD_BUTTON'>
          <a routerLink="/passwordreset" class="forgot-password" i18n="Forgot Password">Forgot Password</a>
        </div>
        <div class="login-btn-area">
          <div class="login" *ngIf="!btnTriggered">
            <button class="btn btn-block btn-secondary" type="submit" i18n="login-title">Log In</button>
            <div toastContainer></div>
          </div>
          <div class="login" *ngIf="btnTriggered">
            <button class="btn btn-primary btn-block" disabled i18n="login-title">Log In</button>
            <div toastContainer></div>
          </div>
        </div>

        <div class="or" *ngIf='loginEnvConfig.GOOGLE_LOGIN'>or</div>
        <div class="other-login">
          <div class="icons" *ngIf='loginEnvConfig.GOOGLE_LOGIN'>
            <a i18n="continue with google" href="{{googleLink}}">
              <img src="https://www.pickcel.com/assets/img/GoogleLogo.svg" alt="Continue with Google">  Continue with Google
            </a>
          </div>                
          <div class="icons" *ngIf='loginEnvConfig.APPLE_LOGIN'>
            <a href="https://appleid.apple.com/auth/authorize?response_type=code+id_token&client_id=com.pickcel&redirect_uri={{appleLink}}&scope=name+email&response_mode=form_post" i18n>    
              <img src="https://www.pickcel.com/assets/img/AppleLogo.svg" alt="Continue with Apple"> Continue with Apple
            </a>
          </div>
        </div>
        
        
        <div class="bottom-area" *ngIf='loginEnvConfig.REGISTRATION_ALLOWED'>
          <p i18n="login | Not a member">Don't have an Account ? </p>
          <a i18n="login | Create new account" (click)="onRegister()">Sign Up</a>
        </div>

      </form>
    </div>
    <!-- <div class="version">
      <p i18n>Version 3.0</p>
    </div> -->
    <!-- <ul class="social-link">
        <li *ngIf='loginEnvConfig.FACEBOOK_LOGIN'>
          <a href={{loginEnvConfig.FACEBOOK_LINK}}>
            <em class="ti-facebook"></em>
          </a>
        </li>
        <li *ngIf='loginEnvConfig.TWITTER_LOGIN'>
          <a href={{loginEnvConfig.TWITTER_LINK}}>
            <em class="ti-twitter-alt"></em>
          </a>
        </li>
        <li *ngIf='loginEnvConfig.LINKEDIN_LOGIN'>
          <a href={{loginEnvConfig.LINKEDIN_LINK}} >
            <em class="ti-linkedin"></em>
          </a>
        </li>
      </ul> -->
  </div>
  <!-- <div class="container-fluid "> -->
    <!-- <div class="row"> -->
      <!-- <div class="right-side">
        <div class="logo" style="text-align: center;">
          <div class="icon">
            <img src="https://www.pickcel.com/assets/img/mediaplayer/appicon.svg" class="img-fluid" alt="">
          </div>
          <img src={{loginEnvConfig.LOGO_SRC}} class="img-fluid" alt="pickcel">
          <h2>Cloud digital signage solution</h2>
        </div>

        <div class="description">
          <p i18n>A powerful, yet easy to use application for managing <br>
            your digital signage displays</p>
        </div>
        <div class="privacy">
          <a href="https://pickcel.com/terms.html" target="_blank" rel="noopener noreferrer" i18n>Terms and Condition</a>
          <p i18n>© 2021 Pickcel.com, All rights reserved. | Privacy</p>
          <!- - <a i18n="login | Terms and Condition" *ngIf='loginEnvConfig.TERMS_AND_CONDITION' href={{loginEnvConfig.TERMS_AND_CONDITION_LINK}} target="_blank">Terms and Condition</a>
          <p i18n>
            {loginEnvConfig.FOOTER_TEXT, select, © 2018 pickcel.com, All rights reserved. | Privacy {© 2018 pickcel.com, All rights reserved. | Privacy} other {{{loginEnvConfig.FOOTER_TEXT}}}}
          </p> - ->
        </div>
      </div> -->
      
</div>