/**
 * @description
 * @author raju.c@pickcel.com
 * @since 16-mar-2018
 */
import { InjectionToken } from '@angular/core';

// by default public & static
export interface ErrorMessageConfig {
    LOGIN_INCORRECT: string;
    EMAIL_REQ: string;
    PASSWORD_REQ: string;
    PASSWORD_INVALID: string;
    FAIL: string;
    ERROR: string;
    WARNING: string;
    BAD_REQUEST: string;
}

export const ERROR_MSG_DI_CONFIG: ErrorMessageConfig = {
    LOGIN_INCORRECT: 'Login incorrect.',
    EMAIL_REQ: 'Email required.',
    PASSWORD_REQ: 'Password required.',
    PASSWORD_INVALID: 'Password is invalid.',
    FAIL: 'Fail',
    ERROR: 'Something went wrong.',
    WARNING: 'You need to change this value further.',
    BAD_REQUEST: 'Server return bad request.'
};

export const ERROR_MSG_CONFIG = new InjectionToken<ErrorMessageConfig>('errors');
