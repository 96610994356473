/**
 * @description
 * @author raju.c@pickcel.com
 * @since 16-mar-2018
 */
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from './cookie.service';
import { TokenService } from './token.service';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class HomeGuard implements CanActivate {

    constructor(
        private cookieService: CookieService,
        private router: Router,
        private toastr: ToastrService
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.cookieService.get('accessToken') && this.cookieService.get('isLoggedIn') === 'true') {
            return true;
        } else {
            this.router.navigate(['/']);
            // this.toastr.error('Please login again.', 'You have been logged out!');
            return false;
        }
    }
}


