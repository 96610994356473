
import { throwError as observableThrowError } from 'rxjs';

import { catchError } from 'rxjs/operators/catchError';



import { map } from 'rxjs/operators/map';

import { switchMap } from 'rxjs/operators/switchMap';

import { distinctUntilChanged } from 'rxjs/operators/distinctUntilChanged';

import { debounceTime } from 'rxjs/operators/debounceTime';
import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { Observable } from 'rxjs';



import { ErrorService } from '../../services/error.service';
// import { HttpClient } from '@angular/common/http';
import { Config } from '../../constants';
import { TokenService } from '../../services/token.service';
import { ErrorHandlerService } from '../../services/error-handler.service';

@Injectable()
export class TagsService {
  config = new Config();
  baseUrl: string;
  queryUrl = '?search=';
  private access_token;
  private headers: Headers = new Headers();
  tagAPI: any;

  constructor(
    // private http: HttpClient,
    private _http: Http,
    private _tokenService: TokenService,
    private _errorService: ErrorService,
    private _errorHandler: ErrorHandlerService,
  ) {
    this.baseUrl = this.config.URL;
    this.access_token = this._tokenService.getCurrentUserToken();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('x-access-token', 'Bearer ' + this.access_token);
    //GETTING TAGS API
    this.tagAPI = this.config.TAG_API;
  }

  search(terms: Observable<string>) {
    return terms.pipe(debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => this.searchEntries(term)));
  }

  searchEntries(term) {
    return this._http
      .get(this.baseUrl + '/tags/find' + this.queryUrl + term, { headers: this.headers }).pipe(map(res => res.json()), (catchError((error:any)=>{
        return this._errorHandler.handleError(error);
      })));
  }

  getTags() {
    return this._http
      .get(this.baseUrl + '/tags/find', { headers: this.headers }).pipe(map(res => res.json()));
  }
  addTags(data, type) {
    // console.log(data , type);
    const body = {
      data: data,
      type: type
    };
    return this._http.post(this.baseUrl + '/tags/create', body, { headers: this.headers }).pipe(
      map(res => res.json()),
      catchError((e: any) => observableThrowError(this._errorService.handleError(e))));
  }

  findTagsByType(type) {
    return this._http
      .get(this.baseUrl + '/tags/findByType/' + type, { headers: this.headers }).pipe(map(res => res.json()));
  }


  getNewTags(type) {
    return this._http.get(`${this.tagAPI}/getMasterTags?type=${type}`, { headers: this.headers }).pipe((catchError((error:any)=>{
      return this._errorHandler.handleError(error);
    })),map((res) =>res.json())); 
  }

  updateTags(body) {
    return this._http.post(`${this.tagAPI}/updateTag`, body, { headers: this.headers }).pipe(map(res => res.json()));
  }

  removeTags(body) {
    return this._http.post(`${this.tagAPI}/removeTags`, body, { headers: this.headers }).pipe(map(res => res.json()));
  }

  addTag(body) {
    return this._http.post(`${this.tagAPI}/addTag`, body, { headers: this.headers }).pipe(map(res => res.json()));
  }

  removeTag(type, value, id) {
    console.log(type, value, id);
    console.log(this.tagAPI);
    return this._http.get(`${this.tagAPI}/removeTag?type=${type}&id=${id}&tag=${value}`, { headers: this.headers }).pipe(map(res => res.json()));
  }

}
