import { Component, Inject, OnInit } from '@angular/core';
import { TokenService } from './shared/services/token.service';
import { ActivatedRoute, Router, Route } from '@angular/router';
import { CookieService } from './shared/services/cookie.service';
import { DOCUMENT } from "@angular/common";
import { config } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  public checkToken;
  public userData; isValid;
  constructor(private _tokenService: TokenService,
    private cookieService: CookieService,
    private _router: Router,
    private translate: TranslateService,
    @Inject(DOCUMENT) private _document: HTMLDocument) {
    this.checkToken = this._tokenService.getCurrentUserToken();
    this.userData = this._tokenService.getCurrentUserData();
    // if (!this.checkToken) {
    //   this.isValid = true;
    //   this._router.navigate(['/']);
    // }
    this._document.getElementById('appFavicon').setAttribute('href', config.FAVICON_PATH);
    // this._document.getElementById('appBaseRef').setAttribute('href', config.APP_BASE_REF);
  }
  ngOnInit() {
    // throw new Error("Method not implemented.");
    this.translate.setDefaultLang('sweetalert');
    this.translate.use('sweetalert');
  }
}

// On Clicking browser Back button page gets reloaded.
// $(window).on('popstate', function () {
//     location.reload(true);
// });
