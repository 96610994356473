
import {throwError as observableThrowError} from 'rxjs';
/**
 * @description
 * @author raju.c@pickcel.com
 * @since 16-mar-2018
 */
import { Injectable, Inject } from '@angular/core';
import { Headers, Http } from '@angular/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { User } from './user.model';

import { Config } from '../constants/config';
import { ErrorService } from './error.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LoggerService } from './logger.service';
// import { Reset } from './forgot-password/reset.model';

import { Country } from './country';
import { COUNTRIES_IO } from '../constants/countries-io';
import { COUNTRIES } from '../constants/countries';
import { config } from '../../../environments/environment';

@Injectable()
export class LoginService {
    public sessionStatus = config.SESSION_AUTO_EXPIRED;
    config = new Config();
    private url; crmUrl; subscriptionUrl;
    private headers: Headers = new Headers();
    access_token: string;

    constructor(
        private http: Http,
        private tokenService: TokenService,
        public _errorService: ErrorService,
        private toastr: ToastrService,
        private router: Router,
        private loggerService: LoggerService,
    ) {
        this.url = this.config.USER_API;
        this.crmUrl = this.config.CUSTOMER_API;
        this.subscriptionUrl = this.config.SUBSCRIPTION_API;
        this.access_token = this.tokenService.getCurrentUserToken();
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('x-access-token', 'Bearer ' + this.access_token);
    }

    getUserData(id) {
        return this.http.get(`${this.config.SERVER_URL}/api/v3/users/get_social_user_data/${id}`).pipe(map(res => res.json()));
    }

    /**
     * @description This method allows the use to login
     * @method login()
     * @param user
     */
    login(user: any): Observable<any> {
        const url = `${this.url}/login`;
        const details:any = {
            "email": btoa(user.email),
            "password": btoa(user.password),
            "domain": user.domain,
        }
        return this.http.post(url, details, { headers: this.headers })
            .pipe(
            map(res => res.json()),
        );
    }

    checkPartner() {
        return this.http.get(`${this.config.SERVER_URL}/utils/checkPartner/${window.location.hostname}`);
    }

    /**
      * @description this method allows the user to logout
      * @method logout()

      */

    logout() {
        this.access_token = this.tokenService.getCurrentUserToken();
        if (!this.headers.has('x-access-token')) {
            this.headers.append('x-access-token', 'Bearer ' + this.access_token);
        }
        const url = `${this.url}/logout`;
        // this.router.navigate(['']);
        this.http.get(url, { headers: this.headers }).subscribe((data) => {
            // this.toastr.success('Logout Successful', '', {
            //     // closeButton: true,
            //     timeOut: 2000,
            //     positionClass: 'toast-top-center'
            // });
            console.log('Logout data is: ', data);
            this.tokenService.removeToken();
            this.tokenService.removeUserData();
            this.tokenService.removeCountryData();
            this.tokenService.removeSubscriptionData();
            this.tokenService.removeSalesIQ();
            this.router.navigate(['/']);
            if(this.sessionStatus) {
                window.location.reload();
            }
            sessionStorage.removeItem('templatePath');
            localStorage.setItem('redirect_path', '');
            localStorage.removeItem('perms');

        });
        return this.http.get(url, { headers: this.headers })
            .pipe(
            map(res => res.json()),
        );
    }
    /**
    * @description This method allows the use to Signup
    * @method SignUp()
    * @param user
    */
    SignUp(user): Observable<any> {
        const url = `${this.url}/signup`;
        this.loggerService.info('User :', user);
        return this.http.post(url, user, { headers: this.headers }).pipe(map(res => res.json()));
    }

    createLeads(user): Observable<any> {
        const url = `${this.crmUrl}/leads/create`;
        return this.http.post(url, user, { headers: this.headers }).pipe(
            map(res => res.json()),
            catchError((e: any) => observableThrowError(this._errorService.handleError(e))),);
    }

    createCustomer(customer_details): Observable<any> {
        const url = `${this.subscriptionUrl}/customer/create`;
        const details = {
            details: customer_details
        };
        return this.http.post(url, details, { headers: this.headers }).pipe(
            map(res => res.json()),
            catchError((e: any) => observableThrowError(this._errorService.handleError(e))),);
    }
    createSubscription(user, customerRes): Observable<any> {

        const userData = {
            user: user,
            customerRes: customerRes
        };

        const url = `${this.subscriptionUrl}/create`;
        return this.http.post(url, userData, { headers: this.headers }).pipe(
            map(res => res.json()),
            catchError((e: any) => observableThrowError(this._errorService.handleError(e))),);
    }

    subscribeUser(details) {
        const url = `${this.subscriptionUrl}/subscribeUser/`;
        return this.http.post(url, details, { headers: this.headers })
        .pipe(map(res => res.json()),
            catchError((e: any) => observableThrowError(this._errorService.handleError(e))));
    }

    getCountries() {
        return COUNTRIES_IO;
        // return t<Country[]>()
        // .pipe(
        //   tap(heroes => this.log('fetched heroes')),
        //   catchError(this.handleError('getHeroes', []))
        // );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            this.loggerService.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.loggerService.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}


export class ResetService {
    config = new Config();
    private url;
    private headers: Headers = new Headers({ 'Content-Type': 'application/json' });
    constructor(
        private http: Http,
        private tokenService: TokenService,
        // public _errorService: ErrorService
    ) {
        //  this.url = config.REGISTRATION;
    }
    /**
     * @description This method allows the use to reset
     * @method Reset()
     * @param user
     */
    Reset(user) {
        this.http.post(`${this.url}/reset`, user, { headers: this.headers })
            .subscribe((data) => { console.log(data); });
        // .map(res => res.json())
        // .catch((e: any) => Observable.throw(this._errorService.handleError(e)));
    }

}

@Injectable()
export class NewPasswordService {
    private url;
    config = new Config();

    private headers: Headers = new Headers({ 'Content-Type': 'application/json' });
    constructor(
        private http: Http,
        private tokenService: TokenService
    ) {
        this.url = this.config.USER_API;
        const access_token = this.tokenService.getCurrentUserToken();
        this.headers.append('x-access-token', 'Bearer ' + access_token);
    }

    newPassword(data) {
        const url = `${this.url}/update?type=webApp`;
        return this.http.post(url, data, { headers: this.headers }).pipe(
            map(res => res.json()));
        // .catch((e: any) => Observable.throw(this._errorService.handleError(e)));
    }
}
