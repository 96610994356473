
    <div class="custom-toastr" style="display:flex; flex-direction: column;" [style.backgroundColor]="backCol" [style.display]="state.value === 'inactive' ? 'none' : ''">
      <div class="custom-toastr-header" style="display: flex;">
        <img [src]="headerImg" class="custom-toastr-icon">
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
          {{ title }}
        </div>
        <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
          [class]="options.messageClass" [innerHTML]="message">
        </div>
        <button (click)="remove()" class="close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div *ngIf="showBody" class="custom-toastr-body">
        <div *ngIf="message && !options.enableHtml" style="font-size:small" role="alert" aria-live="polite"
        [class]="options.messageClass" [attr.aria-label]="message">
          {{ message }}
        </div>
        <div class="custom-toastr-btns">
          <div class="form-check">
            <input (change)="detectChange($event)" class="form-check-input custom-toastr-checkbox" style="margin: 0;" type="checkbox" value="" id="flexCheckDefault"/>
            <label style="margin-left: 20px; font-size: 11px;" class="form-check-label" for="flexCheckDefault"> Don't show again</label>
          </div>
          <div class="action-btn" (click)="action($event)">
            <a *ngIf="!options.closeButton" style="float: right; padding-left:15px; padding-right: 15px;" class="btn btn-primary btn-sm" >
            <img [src]="bottomImg"> Continue
            </a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
    