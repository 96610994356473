
// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomModule } from '../custom/custom.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppsModule } from 'src/app/index/apps/apps.module';


// Components 
import { HeaderComponent } from './menus/header/header.component';
import { SideNavComponent } from './menus/side-nav/side-nav.component';
import { UserVerifyComponent } from './user-verify/user-verify.component';
import { BellNotifyComponent } from './bell-notify/bell-notify.component';
import { FullPageComponent } from './fullpage-loader/fullpage.component';
import { FieldValidationComponent } from './field-validation.component';
import { PermissionComponent } from './permission-check.component';
import { LabelTagsComponent } from './label-tags/label-tags.component';
import { LabelTagsContentComponent } from './label-tags/label-tags-content/label-tags-content.component';
import { TagsFilterViewComponent } from './tags-filter-view/tags-filter-view.component';
import { PexelsComponent } from './upload/pexels/pexels.component';
import { PixabayComponent } from './upload/pixabay/pixabay.component';
import { UnsplashComponent } from './upload/unsplash/unsplash.component';
import { GroupsComponent } from './groups/groups.component';
import { FileUploadComponent } from './upload/file-upload/file-upload.component';
import { MediaUploadComponent } from './upload/media-upload/media-upload.component';


// Services
import { NotificationService } from './notification.service';
import { UserService } from 'src/app/index/user/user.service';
import { PexelsService } from '../services/pexels.service';
import { PlaceholderService } from '../services/placeholder.service';

import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import { environment } from 'src/environments/environment';
import { ZohoTicketComponent } from './zoho-ticket/zoho-ticket.component';
import { PlansService } from 'src/app/index/plans/plans.service';

// Initialize Firebase
firebase.initializeApp(environment.firebase);

@NgModule({
    declarations: [
        HeaderComponent,
        SideNavComponent,
        UserVerifyComponent,
        BellNotifyComponent,
        FullPageComponent,
        FieldValidationComponent,
        PermissionComponent,
        LabelTagsComponent,
        LabelTagsContentComponent,
        TagsFilterViewComponent,
        // PexelsComponent,
        // PixabayComponent,
        // UnsplashComponent,
        GroupsComponent,
        ZohoTicketComponent
        // MediaUploadComponent,
        // FileUploadComponent,
        // AppsModule
    ], 
    imports: [
        RouterModule,
        CustomModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        // AppsModule
    ],
    exports: [
        HeaderComponent,
        SideNavComponent,
        FullPageComponent,
        FieldValidationComponent,
        PermissionComponent,
        LabelTagsComponent,
        LabelTagsContentComponent,
        TagsFilterViewComponent,
        // PexelsComponent,
        // PixabayComponent,
        // UnsplashComponent,
        GroupsComponent,
        ZohoTicketComponent
        // MediaUploadComponent,
        // FileUploadComponent
    ],
    providers: [NotificationService, UserService, PexelsService, PlaceholderService, PlansService],

})
export class MenusModule { }
