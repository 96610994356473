import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CommonService {

    private appAssetData = new BehaviorSubject<any>([]);
    appDet = this.appAssetData.asObservable();

    constructor() { }


    appData(data) { // TODO remove it and use below setter, getter by passing key & value
        // console.log('Data in common service is: ', data);
        sessionStorage.setItem('appData', JSON.stringify(data));
        // console.log('ss : ', data);
        // this.appAssetData.next(data);
    }
    // added on 20-08-2018 by uday
    setData(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    getData(key: string): string {
        return sessionStorage.getItem(key);
    }

    removeData(key: string): void {
        return sessionStorage.removeItem(key);
    }

}

