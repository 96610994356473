//Angular core modules

import { CommonModule } from '@angular/common';

//Browser module
import { BrowserModule } from '@angular/platform-browser';

//angular modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

//form module
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//router module
import { RouterModule } from '@angular/router';

//Angular datatable
import { DataTablesModule } from 'angular-datatables';

//For http request
import { HttpModule } from '@angular/http';

//HttpClient module
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Third party modules
//bootstrap module
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//Toast messages in window
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';

//To calculate Idle system time
import { BnNgIdleService } from 'bn-ng-idle';

//Canva app service
import { CanvaService } from "./index/assets/canva-init"

//Custom modules
//Application routing module
import { AppRoutingModule } from './app-routing.module';

//App component
import { AppComponent } from './app.component';

//Login component for authentication
import { LoginComponent } from './login/login.component';

//Menu module 
import { MenusModule } from './shared/components/menus.module';

//services
import { LoggerService, CookieService, ErrorService, TokenService, } from './shared/services';

//Token interceptor
import { TokenInterceptor } from './http-interceptor';

//Tag services
import { TagsService } from './shared/components/tags/tags.service';

import { BlockMobileBrowserComponent } from "./shared/components/block-mobile-browser/block-mobile-browser.component";

//Abzolute partner login component
import { AbzLoginComponent } from './abz-login/abz-login.component';

//Taggio partner login component
import { TaggioComponent } from './taggio/taggio.component';
import { AllowInputDirective } from './shared/directives/allow-input.directive';
import { AclService } from 'ng2-acl';
import { PinkToast } from './shared/constants/pink.toast';

import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');

  //return new TranslateHttpLoader(httpClient, 
    //environment.feServerUrl + '/assets/i18n/', '.json'); 
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AbzLoginComponent,
    TaggioComponent,
    //AllowInputDirective,
    BlockMobileBrowserComponent,
    PinkToast
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MenusModule,
    DataTablesModule,
    HttpModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    TagsService,
    LoggerService,
    CookieService,
    ErrorService,
    AclService,
    TokenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    BnNgIdleService,
    CanvaService
  ],
  entryComponents:[PinkToast],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }