'use strict';

/** @author uday.kumar@pickcel.com
 *  @description user-verification
 *  @since 17-05-2018
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { Headers } from '@angular/http';
import { LoginService } from '../../services/auth.service';
import { config } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
// import * as $ from 'jquery';
// import 'bootstrap-notify';

// template: `<h4> Loading...</h4>`
@Component({
    selector: 'app-user-verify',
    template: ``,
    providers: [LoginService],
})
export class UserVerifyComponent implements OnInit {
    // baseUrl;
    public headers: Headers = new Headers();
    uID: string;
    public DOMAINS = [];
    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _tokenService: TokenService,
        private _loginService: LoginService,
        private _toastr: ToastrService,
    ) {
        this.headers.append('x-access-token', 'Bearer ' + this._tokenService.getCurrentUserToken());
    }

    ngOnInit() {
        this._tokenService.setToken(this._route.snapshot.params.jwt);
        this.getUserData(this._route.snapshot.params.UD);
        //To check Allowed domains
        if (config.ALLOWED_DOMAINS && config.ALLOWED_DOMAINS.length > 0) {
            this.DOMAINS = config.ALLOWED_DOMAINS;
        } else {
            this.DOMAINS = [config.SERVER_NAME];
        }
    }

    getUserData(id) {

        this._loginService.getUserData(id).subscribe((loggedInUser:any) => {
            console.log("user", loggedInUser);
            if (loggedInUser) {

                // user.userData.settings = user.settings;
                // user.userData.role = user.menus.role;
                // user.userData.loggedInType = "GOOGLE";
                // this._tokenService.setUser(user.userData, user.menus);
                // this._tokenService.setCountryData('');
                // this._router.navigate(['/index']);

                if (loggedInUser.userData.partner) {
                    let domainStatus = false;
                    if (this.DOMAINS && this.DOMAINS.length > 0 && this.DOMAINS.includes(window.location.hostname)) {
                        domainStatus = true;
                    }
                    if (loggedInUser.userData.partner.domain === window.location.hostname || window.location.hostname === 'localhost' || domainStatus) {
                        loggedInUser.userData.settings = loggedInUser.settings;
                        loggedInUser.userData.role = loggedInUser.menus.role;
                        loggedInUser.userData.loggedInType = "GOOGLE";
                        
                        if(loggedInUser.userData.subscription_method === "MANUAL") {
                            loggedInUser.menus.menus = loggedInUser.menus.menus.filter(item=> item.id != 'Plan');
                        }
                        this._tokenService.setUser(loggedInUser.userData, loggedInUser.menus);
                        this._tokenService.setCountryData('');
                        if (loggedInUser.userData.messages) {
                            this._tokenService.storeNotifications(loggedInUser.userData.messages);
                        }
                        localStorage.setItem('notification', '');
                        if(localStorage) {
                            localStorage.setItem("zoho-email", loggedInUser.userData.email);
                        }
                        this._router.navigate(['/index']);
                    } else {
                        this._toastr.error('Login Failed.');
                    }
                } else {
                    this._toastr.error('Login Failed.');
                }
            } else {
                // this.toastr.error('Login Failed.');
            }

        }, (err) => {
        });

    }
// console.log("user 111", JSON.stringify(JSON.parse(this._route.snapshot.params.UD)));
// // console.log("decodeURI", JSON.parse(decodeURI(this._route.snapshot.params.UD)));
// this._tokenService.setUser(this._route.snapshot.params.UD);
  // console.log("user", this._tokenService.getCurrentUserData());
        // this._tokenService.setCountryData('');
        // this._tokenService.setToken(this._route.snapshot.params.jwt);
        // this._router.navigate(['/index/screen']);
    // }


}
