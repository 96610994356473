/**
 * @description
 * @author raju.c@pickcel.com
 * @since 16-mar-2018
 */
//Core module
import { Component, OnInit } from '@angular/core';

import { FormGroup, Validators, FormControl} from '@angular/forms';

import { Router } from '@angular/router';

//Third party modules
//toast messages
import { ToastrService } from 'ngx-toastr';



//Custome modules
//services
import { Validation, CookieService, TokenService, LoggerService, LoginService } from '../shared/services';

//user model
import { User } from '../shared/services/user.model';

//Field validation component for form validation
import { FieldValidationComponent } from '../shared/components/field-validation.component';

// api constants
import { Config } from '../shared/constants';

//environment constants
import { config } from '../../environments/environment';


@Component({
    selector: 'app-login',
    templateUrl: './taggio.component.html',
    styleUrls: ['./taggio.component.css'],
    providers: [LoginService, Validation, FieldValidationComponent, TokenService, CookieService]
})

export class TaggioComponent implements OnInit {
    //server api constant values
    private serverConfig = new Config();

    //environment constant values
    public loginEnvConfig:any = config.LOGIN;

    //Login form
    public loginForm: FormGroup;
    
    //user model
    public user: User = new User();
    
    //gogole link
    public googleLink:string = this.serverConfig.G_LOGIN;

    //Facebook link
    public fbLink:string = this.serverConfig.F_LOGIN;

    //twitter link
    public twitterLink:string = this.serverConfig.TWITTER_LOGIN;

    //LinkedIn link 
    public linkedinLink:string = this.serverConfig.LINKEDIN_LOGIN;

    //Validation messages
    public validation:any = this._validationMsg.loginValidationMessages;

    //LoggedIn user details
    private _userData:any;

    //Login form error fields
    public loginFormError:any = {
        email: '',
        password: ''
    };

    //Login form validation messages
    public validationMessages:any = {
        email: {
            required: 'Email is required.',
            pattern: 'Please enter valid email.',
        },
        password: {
            required: 'Password is required.',
            minlength: 'Password must be 6 characters.'
        }
    };

    constructor(
        private _router: Router,
        private _validationMsg: Validation,
        private _loginService: LoginService,
        private _tokenService: TokenService,
        private _toastr: ToastrService,
        private _fieldValid: FieldValidationComponent,
        private _loggerService: LoggerService
    ) {
    }

    /**
     * @description Angular life cycle method
     */
    ngOnInit(): void {
        //To create login form
        this.createLoginForm();

        //If token exist it will direct to screen module directly
        if (this._tokenService.getCurrentUserToken()) {
            this._router.navigate(['/index/screen']);
        }
    }

    /**
     * @description To create login form
     */
    createLoginForm(): void {
        this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,8}$')]),
            password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        }, { updateOn: 'submit' });

        this.loginForm.valueChanges.subscribe((formValue) => {
            this._fieldValid.validate(formValue, this.loginForm, this.loginFormError, this.validationMessages);
        });
    }

    /**
     * @description To login in server
     */
    onLogin(): void {
        if (this.loginForm.valid) {
            this.loginForm.value.domain = window.location.hostname;
            this._loginService.login(this.loginForm.value).subscribe((user) => {
                if (user) {
                    this._toastr.success(user.message, '', {
                        timeOut: 2000,
                        positionClass: 'toast-top-center'
                    });

                    if (user.userData.partner) { // this is if checking for partner
                        if (user.userData.partner.domain === window.location.hostname || window.location.hostname === 'localhost') {
                            user.userData.settings = user.settings;
                            user.userData.role = user.menus.role;
                            this._tokenService.setUser(user.userData, user.menus);
                            this._tokenService.setCountryData('');
                            this._tokenService.setToken(user.token);
                            this._userData = JSON.parse(this._tokenService.getCurrentUserData());
                            this._router.navigate(['/index']);
                        } else {
                            this._toastr.error('Login Failed.');
                        }
                    } else {
                        this._toastr.error('Login Failed.');
                    }
                }
            }, (err) => {
                if (err.status === 422) {
                    this._toastr.error('Email or Password is wrong.', 'Login Failed', {
                        timeOut: 2000,
                        positionClass: 'toast-top-center'
                    });
                } else if (err.status === 400) {
                    this._toastr.error('Please verify your email or contact admin.', 'Login Failed', {
                        closeButton: false,
                        positionClass: 'toast-top-center',
                        timeOut: 3000
                    });
                } else {
                    this._toastr.error('Login Failed.');
                }
            });
        } else {
            this._fieldValid.validateAllFormFields(this.loginForm, this.loginFormError, this.validationMessages);
        }
    }

    /**
     * @description To redirect to register page
     */
    onRegister(): void {
        this._router.navigate(['/register']);
    }
}
