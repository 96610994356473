import { Injectable } from '@angular/core';
import { config } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CanvaService {
    loadCanvaIndex = 0;
    loadCanvaLimit = 6;
    canvaKey = "";
    canvaApi: any;
    constructor() {
        this.canvaKey = config.CANVA_APP_KEY;
    }
    async loadCanva() {
        console.log("canva initialization invoked => ");

        try {
            if (this.canvaApi == undefined) {

                this.loadCanvaIndex++;
                if (window && window['Canva'] && window['Canva'].DesignButton) {
                    let canvaLink = await window['Canva'].DesignButton.initialize({
                        apiKey: this.canvaKey,
                    });
                    if (canvaLink) {
                        this.canvaApi = canvaLink;
                    }
                    console.log("****** Canva App Loaded *****");
                    return this.canvaApi;
                } else {
                    console.log("canva loading issue");
                    if (this.loadCanvaIndex <= this.loadCanvaLimit) {
                        setTimeout(() => {
                            this.loadCanva();
                        }, 20000);
                    }
                }
            } else {
                console.log("canva api available")
                return this.canvaApi;
            }
        } catch (err) {
            console.log("Error :", err);
        }
    }
}