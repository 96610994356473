/**
 * @description
 * @author raju.c@pickcel.com
 * @since 16-mar-2018
 */
export class User {

    constructor(
        public username?: string,

        public password?: string,

    ) { }

}
