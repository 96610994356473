'use strict';
/**
 * @description To fetch products, plans, and url.
 * @since 10-july-2018
 * @author uday.kumar@pickcel.com,
 * @updated_at 24-08-2018 with trial & live subscription, invoice & payment url
 * @updated_by  uday.kumar@pickcel
 */
import { Injectable } from '@angular/core';



import { Config } from '../../shared/constants/config';
import { HttpClient } from '@angular/common/http';
import { TokenService } from 'src/app/shared/services';
import { Headers, Http } from '@angular/http';
@Injectable()
export class PlansService {
  config = new Config();
  private url; invoice_url; entity_url; plan_url;
  public access_token;
  private headers: Headers = new Headers();
  //  private headers: Headers = new Headers();
  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService,
    private http: Http,
  ) {
    this.access_token = this._tokenService.getCurrentUserToken();
    this.url = this.config.SUBSCRIPTION_API;
    this.entity_url = this.config.ENTITY_URL;
    this.invoice_url = this.config.INVOICE_API;
    this.plan_url = `${this.config.URL}/plans`;
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('x-access-token', 'Bearer ' + this.access_token);

  }
  // not using
  getProducts() {
    return this._http.get(`${this.url}/products/find`);
  }
  getAllPlans() {
    // return this._http.get(`${this.url}/plans/find`);
    return this._http.get(`${this.plan_url}/list`);
  }
  getPlanUrlByCode(code) {
    return this._http.get(`${this.url}/plans/findPlanUrl/${code}`);
  }
  // not using
  getPlansByProductId() {
    return this._http.get(`${this.url}/plans/findById`);
  }



  getSubscriptionId = () => {
    // this.organisationDocument = this.afs.collection('organisations').doc(atob(this.cookieService.get('OID')));
    // this.organisation = this.organisationDocument.valueChanges();
    // return this.organisation;
  }

  getSubscriptionDetails = (subscription_id) => {
    console.log(subscription_id, 'subscription_id');
    return this._http.get(`${this.url}/find/${subscription_id}`);
  }

  upgradeSubscription = (details) => {
    return this._http.put(`${this.url}/update/${details.subscription_id}`, details);
  }

  getCustomerInvoices = (subscription_id) => {
    // return this._http.get(`${this.invoice_url}/find/${subscription_id}`);
    return this._http.get(`${this.url}/getInvoiceList/${subscription_id}`);
  }


  getInvoice = (invoice_id) => {
    return this._http.get(`${this.invoice_url}/find?invoice_id=${invoice_id}`);
  }

  getSubscriptionIdFromEntity = (entity_id): any => {
    return this._http.get(`${this.entity_url}/details/${entity_id}`);
  }

  updateCustomer = (details) => {

    // const promise = new Promise((resolve, reject) => {

    //   const headers = new Headers({ 'Content-Type': 'application/json' });

    //   const url = 'https://us-central1-' + environment.firebase.projectId + '.cloudfunctions.net/rebliss/updateCustomer';

    //   return this._http.post(url, details, {headers:headers})
    //     .subscribe(res => {

    //       resolve(res);

    //     });
    // });
    // return promise;
  }


  getZohoPaymentForPlan = (planCode) => {
    // const promise = new Promise((resolve, reject) => {
    //     const headers = new Headers({ 'Content-Type': 'application/json' });
    //     const plan_code = {
    //         'planCode': planCode
    //     };
    //     const url = 'https://us-central1-' + environment.firebase.projectId + '.cloudfunctions.net/rebliss/getPlanUrl';
    //     return this._http.post(url, plan_code, { headers: headers })
    //     .subscribe(res => {
    //         resolve(res);
    //     });
    // });
    // return promise;
  }

  getPayPalPaymentForPlan = (dataForPayPal) => {
    // const promise = new Promise((resolve, reject) => {
    //     const headers = new Headers({ 'Content-Type': 'application/json' });

    //     const url = 'https://us-central1-' + environment.firebase.projectId + '.cloudfunctions.net/rebliss/payByPayPal';
    //     return this._http.post(url, dataForPayPal, { headers: headers })
    //     .subscribe(res => {
    //         resolve(res);
    //     });
    // });
    // return promise;
  }

  sendUpgradeRequest = (details) => {
    return this._http.post(`${this.url}/sendRequest/${details.subscription_id}`, details);
  }

  updateSubscription(details) {
    return this._http.post(`${this.url}/upgradeSubscription`, details);
    // return this._http.post(`https://subscriptions.zoho.com/api/v1/hostedpages/newsubscription`, details);
  }

  cancelSubscription(subscriptionId) {
    return this._http.get(`${this.url}/cancelSubscription/${subscriptionId}`);
  }

  updateLicense(dataToUpdate) {
    return this._http.post(`${this.url}/updateLicense`, dataToUpdate);
  }

  removeLicense(dataToRemove){
    return this._http.put(`${this.url}/decrementLicense`, dataToRemove);
  }

  updateCard(dataToUpdate) {
    return this._http.post(`${this.url}/updateCard`, dataToUpdate);
  }


  getSubscriptionUpdate(data) {
    let type = data
    return this.http.get(`${this.url}/updateHostedPageStatus?type=${type}`, { headers: this.headers });
  }

  updateZohoCustomer(details) {
    return this.http.post(`${this.url}/updateZohoCustomer`, details, { headers: this.headers });
  }

  /** Get IP Address */
  getIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json').map(res => res.json());
  }

  getLocation() {
    let headers: Headers = new Headers({
      "x-rapidapi-key": "0dc31021d9mshcc2044fe27e0346p19d5a0jsnd80d217f8f85",
      "x-rapidapi-host": "ip-geo-location.p.rapidapi.com"
    });
    return this.http.get(`https://ip-geo-location.p.rapidapi.com/ip/check?format=json`, {headers: headers}).map(res => res.json());
  }
}