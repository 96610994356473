import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { config, environment } from 'src/environments/environment';

@Component({
  selector: 'app-block-mobile-browser',
  templateUrl: './block-mobile-browser.component.html',
  styleUrls: ['./block-mobile-browser.component.scss']
})
export class BlockMobileBrowserComponent implements OnInit {
  public view = '';
  public logoUrl:string = '';
  public clientName:string = '';
  public showPickcelGo:boolean = false;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
  ) { 
    this.view = this._activatedRoute.snapshot.paramMap.get('view');
  }

  ngOnInit(): void {
    this.logoUrl = config.LOGIN.LOGO_SRC;
    this.clientName = config.CLIENT_NAME;
    this.showPickcelGo = config.SHOW_PICKCEL_GO;
  }

  changeView(currentView) {
    this.view = currentView == 'login' ? 'register' : 'login';
    this._router.navigate([`/unsupported-browser/${this.view}`])
  }

}
