
import {tap} from 'rxjs/operators/tap';
import { Injectable } from '@angular/core';
import {
   HttpEvent,
   HttpInterceptor,
   HttpHandler,
   HttpRequest,
   HttpResponse,
   HttpErrorResponse
} from '@angular/common/http';

import { TokenService } from './shared/services/token.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()

export class TokenInterceptor implements HttpInterceptor {

   constructor(
       private tokenService: TokenService,
       private _router: Router
   ) { }

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       const publicUrls = ['/anyPublicUrl'];

       if (publicUrls.includes('/actualPath')) {
           return next.handle(req);
       } else if (req.headers.get('No-Authorization')) {
           return next.handle(req);
       } else {
           const authReq = req.clone({
               setHeaders: {
                   'x-access-token': 'Bearer ' + this.tokenService.getCurrentUserToken()
               }
           });
           return next.handle(authReq).pipe(tap((event: HttpEvent<any>) => {
               if (event instanceof HttpResponse) {
                   console.log("event");
                }
           }, (err: any) => {
               if (err instanceof HttpErrorResponse) {
                   if (err.status === 401) {
                       this.removeAll();
                       this.alertNotification(401);
                   } else if (err.status === 500) {
                       this.alertNotification(500);
                   } else if (err.status === 0) {
                       this.alertNotification(0);
                   }
               }
           }));
       }
   }

   removeAll() {
       this.tokenService.removeToken();
       this.tokenService.removeUserData();
       this._router.navigate(['/login']);
   }

   alertNotification(code) {
       console.log("Code ::", code);
   }
}