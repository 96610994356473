import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, ElementRef } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { Router } from '@angular/router';
//Sweet Alert
import Swal from 'sweetalert2';
import { CookieService, TokenService } from '../services';

@Component({
  selector: '[pink-toast-component]',
  styles: [`
      :host {

        position: relative;
        overflow: hidden;
        margin: 0;
        padding: 10px;
        width: 300px;
        height: 100%;
        border-radius: 16px;
        color: #000000;
        pointer-events: all;
        cursor: pointer;
        box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.5) !important;
        border-radius: 16px !important;
        background-color: white;
        top: 28px;
        right: 28px;
      }

      .btn-pink {
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0);
      }
      
      .custom-toastr-header {
        display: flex;
        align-items: center;
        padding-right: 5px;
      }

      .custom-toastr-icon {
        width: 20px;
        margin-right: 5px;
      }

      .custom-toastr-header .toast-title {
        font-size: 12px;
        font-weight: 300;
      }

      .custom-toastr-body .toast-message {
        width: 100%;
        float: left;
        font-size: 15px;
        font-weight: 500;
        margin: 15px 5px;
      }

      .custom-toastr-header .close {
        height: 25px;
        width: 25px;
        font-size: 18px;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: 8px;
        padding: 5px;
      }

      .close:hover {
        border-radius: 30em;
        background: rgba(0, 0, 0, 0.15);
      }

      .custom-toastr-btns {
        width: 100%;
        float: left;
        margin: 5px 0;
      }

      .custom-toastr-btns .form-check {
        width: 50%;
        float: left;
        display: flex;
        padding: 5px;
      }

      .custom-toastr-btns .action-btn {
        width: 50%;
        float: left;
      }
      .custom-toastr-btns .action-btn img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    `],
  template: `
    <div class="custom-toastr" style="display:flex; flex-direction: column;" [style.backgroundColor]="backCol" [style.display]="state.value === 'inactive' ? 'none' : ''">
      <div class="custom-toastr-header" style="display: flex;">
        <img [src]="headerImg" class="custom-toastr-icon">
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
          {{ title }}
        </div>
        <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
          [class]="options.messageClass" [innerHTML]="message">
        </div>
        <button (click)="remove()" class="close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div *ngIf="showBody" class="custom-toastr-body">
        <div *ngIf="message && !options.enableHtml" style="font-size:small" role="alert" aria-live="polite"
        [class]="options.messageClass" [attr.aria-label]="message">
          {{ message }}
        </div>
        <div class="custom-toastr-btns">
          <div class="form-check">
            <input (change)="detectChange($event)" class="form-check-input custom-toastr-checkbox" style="margin: 0;" type="checkbox" value="" id="flexCheckDefault"/>
            <label style="margin-left: 20px; font-size: 11px;" class="form-check-label" for="flexCheckDefault"> Don't show again</label>
          </div>
          <div class="action-btn" (click)="action($event)">
            <a *ngIf="!options.closeButton" style="float: right; padding-left:15px; padding-right: 15px;" class="btn btn-primary btn-sm" >
            <img [src]="bottomImg"> Continue
            </a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
    `,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
          opacity: 0,
        }),
        style({
          transform: 'skewX(20deg)',
          opacity: 1,
        }),
        style({
          transform: 'skewX(-5deg)',
          opacity: 1,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0) skewX(30deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class PinkToast extends Toast {
  // used for demo purposes
  undoString = 'undo';
  optCust: any;
  backCol: any;
  toasterRef: any;
  userData: any;
  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private _router: Router,
    private _cookieService: CookieService,
    private _elemRef: ElementRef,
    private _tokenService: TokenService
  ) {
    super(toastrService, toastPackage);
    // console.log('optionsss',<any>this.options.routeTo)
    this.optCust = <any>this.options;
    // console.log('custom value test', optCust.routeTo);
    // console.log("CHECKKK",this._cookieService.check('toaster'));
    this.userData = JSON.parse(this._tokenService.getCurrentUserData());
    try {
      if (this.userData && !((this.userData.subscription_status === 'trial') || (this.userData.subscription_id === '' && this.userData.subscription_status === 'cancelled'))) {
        this.showBody = false;
      } else if (this._cookieService.check('toaster')) {
        if (this._cookieService.get('toaster') === 'false') {
          this.showBody = false;
        } else {
          this.showBody = true;
        }
      }
    } catch(error) {
      this.showBody = false;
      console.log("Error in pink toaster :: ", error);
    };
    console.log('element ref', this._elemRef.nativeElement.style.backgroundColor);
    // Reference of the toaster 

    this.toasterRef = this._elemRef.nativeElement;
    console.log(this.toasterRef)
    // this.toasterRef.style.backgroundColor = 'black';

    this.initImages();
  }
  headerImg: string = '';
  bottomImg: string = '';
  showBody: boolean = true;
  buttonText: string = 'continue';
  initImages() {
    // console.log(this._cookieService.get('toaster'));
    this.optCust.timeOut = 3000;
    if (this.optCust.action === 'SUCCESS') {
      this.toasterRef.style.backgroundColor = '#F7FFFB'
      if(!this.message || this.message == '') {
        this.showBody = false;
      }
      if(this.showBody) {
        this.optCust.timeOut = 10000;
      }
      if (this.optCust.from === 'SCREEN') {
        this.headerImg = 'assets/toastr/screen.svg';
        this.bottomImg = 'assets/toastr/assetButton.svg';
        // this.buttonText = 'Add Media';
      }
      else if (this.optCust.from === 'ASSETS') {
        this.headerImg = 'assets/toastr/assets.svg';
        this.bottomImg = 'assets/toastr/compositionbutton.svg';
        // this.buttonText = 'Add Media';
      }
      else if (this.optCust.from === 'COMPOSITION') {
        this.headerImg = 'assets/toastr/composition.svg';
        this.bottomImg = 'assets/toastr/schedulebutton.svg';
        // this.buttonText = 'Create Schedule';
      } else if (this.optCust.from === 'SCHEDULE') {
        this.headerImg = 'assets/toastr/schedule.svg';
      } else if (this.optCust.from === 'APPS') {
        this.headerImg = 'assets/toastr/apps.svg';
        this.bottomImg = 'assets/toastr/compositionbutton.svg';
      } else if (this.optCust.from === 'SETTINGS') {
        this.headerImg = 'assets/toastr/settings.svg';
      } else if (this.optCust.from === 'REPORTS') {
        this.headerImg = 'assets/toastr/reports.svg';
      } else if (this.optCust.from === 'PLAN') {
        this.headerImg = 'assets/toastr/plan.svg';
      } else if (this.optCust.from == 'LOGOUT') {
        this.headerImg = 'assets/toastr/logout.svg';
      } else if (this.optCust.from == 'LOGIN') {
        this.headerImg = 'assets/toastr/login.svg';
      } 
    }
    else if (this.optCust.action === 'WARNING') {
      this.showBody = false;
      this.toasterRef.style.backgroundColor = '#FFF2E4'
      this.headerImg = 'assets/toastr/warning.svg'
    }
    else if (this.optCust.action === 'ERROR') {
      this.showBody = false;
      // this.backCol = '#FFEBE9'
      this.toasterRef.style.backgroundColor = '#FFEBE9'
      this.headerImg = 'assets/toastr/error.svg'
    }
  }

  action(event: Event) {
    Swal.close();
    event.stopPropagation();
    // this.undoString = 'undid';
    this.toastPackage.triggerAction();
    console.log('action', this.optCust);
    if (this.optCust.routeTo === 'ASSETS_UPLOAD') {
      this._router.navigate(['index/assets']);
    }
    if (this.optCust.routeTo === 'CREATE_COMPOSITION') {
      this._router.navigate([`index/composition/templates`]);
    }
    if (this.optCust.routeTo === 'CREATE_SCHEDULE') {
      this._router.navigate([`index/schedule-list/schedule`]);
    }
    this.remove();
    return false;
  }


  detectChange(event) {
    //event.stopPropagation();
    Swal.close();
    // console.log('EVENT',event);
    if (event.target.checked) {
      // this.showBody = false;
      this._cookieService.set('toaster', 'false');
    } else {
      this._cookieService.set('toaster', 'true');
    }
  }
}
