/**
 * @description
 * @author raju.c@pickcel.com
 * @since 16-mar-2018
 */
//core modules
import { Component, OnInit } from '@angular/core';

import { FormGroup, Validators, FormControl } from '@angular/forms';

import { Router } from '@angular/router';

//Third party modules
import { ToastrService } from 'ngx-toastr';

//custom modules
import { Validation, CookieService, TokenService, LoginService } from '../shared/services';

//user model for login 
import { User } from '../shared/services/user.model';

//Field validation component for form validation
import { FieldValidationComponent } from '../shared/components/field-validation.component';

//server constant values from config object
import { Config } from '../shared/constants';

//Environment constant values
import { config } from '../../environments/environment';

@Component({
    selector: 'app-abz-login',
    templateUrl: './abz-login.component.html',
    styleUrls: ['./abz-login.component.css'],
    providers: [LoginService, Validation, FieldValidationComponent, TokenService, CookieService]
})

export class AbzLoginComponent implements OnInit {

    //server constant values
    private serverConfig = new Config();

    //Environment constant values
    public loginEnvConfig = config.LOGIN;

    //Login form
    public loginForm: FormGroup;
    
    //Hide/show button 
    public btnTriggered:boolean = false;

    public user: User = new User();
    
    //Google link for google login
    public googleLink:string = this.serverConfig.G_LOGIN;

    //Facebook Link for facebook login
    public fbLink:string = this.serverConfig.F_LOGIN;

    //Twitter Link
    public twitterLink:string = this.serverConfig.TWITTER_LOGIN;

    //LinkedIn Link
    public linkedinLink:string = this.serverConfig.LINKEDIN_LOGIN;

    //Validation messages
    public validation:any = this._validationMsg.loginValidationMessages;

    //To hide/show loader in login page
    public showLoader:boolean = true;

    //Login Form error fields
    public loginFormError:any = {
        email: '',
        password: ''
    };

    //Login form validation messages
    public validationMessages:any = {
        email: {
            required: 'Email is required.',
            pattern: 'Please enter valid email.',
        },
        password: {
            required: 'Password is required.',
            minlength: 'Password must be 6 characters.'
        }
    };

    constructor(
        private _router: Router,
        private _validationMsg: Validation,
        private _loginService: LoginService,
        private _tokenService: TokenService,
        private _toastr: ToastrService,
        private _fieldValid: FieldValidationComponent
    ) {
        this.showLoader = true;
    }

    ngOnInit(): void {
        this.showLoader = true;
        this.createLoginForm();
        if (this._tokenService.getCurrentUserToken()) {
            const initialRoute = (JSON.parse(atob(localStorage.getItem('initialRoute'))));
            if (initialRoute) {
                this._router.navigate([`/index/${initialRoute}`]);
            }
        } else {
            this.showLoader = false;
        }
        const zSupport = document.getElementById('zohohc-asap-web-helper-main');
        if (zSupport) {
            document.getElementById('zohohc-asap-web-helper-main').setAttribute('style', 'display:none');
        }
    }

    createLoginForm(): void {
        this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,8}$')]),
            password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        }, { updateOn: 'submit' });

        this.loginForm.valueChanges.subscribe((formValue) => {
            this._fieldValid.validate(formValue, this.loginForm, this.loginFormError, this.validationMessages);
        });
    }

    onLogin(): void {
        if (this.loginForm.valid) {
            this.btnTriggered = true;
            this.loginForm.value.domain =  window.location.hostname;
            this._loginService.login(this.loginForm.value).subscribe((loggedInuser:any) => {
                    if (loggedInuser) {
                   if (loggedInuser.userData.type === 'user') {
                            this._toastr.success(loggedInuser.message, '', {
                                timeOut: 2000,
                                positionClass: 'toast-top-center'
                            });
                            loggedInuser.userData.settings = loggedInuser.settings;
                            loggedInuser.userData.role = (loggedInuser.menus && loggedInuser.menus.role) ? loggedInuser.menus.role : "";
                            loggedInuser.userData.loggedInType = "LOCAL";
                            this._tokenService.setUser(loggedInuser.userData, loggedInuser.menus);
                            this._tokenService.setCountryData('');
                            this._tokenService.setToken(loggedInuser.token);
                            const initialRoute = loggedInuser.menus.menus.find((itm) => itm.view === true);
                            this._router.navigate([`/index/${initialRoute.route}`]);
                        } else {
                            this._toastr.warning('You are not a user.', '', {
                                timeOut: 2000,
                                positionClass: 'toast-top-center'
                            });
                            this.btnTriggered = false;
                        }
                    }
                }, (err) => {
                    this.btnTriggered = false;
                    if (err.status === 403) {
                        this._toastr.error('Invalid user.', '');
                        return;
                    }
                    if (err.status === 422) {
                        this._toastr.error('Email or Password is wrong.', 'Login Failed', {
                            timeOut: 2000,
                            positionClass: 'toast-top-center'
                        });
                    } else if (err.status === 400) {
                        this._toastr.error('Please verify your email or contact admin.', 'Login Failed', {
                            closeButton: false,
                            positionClass: 'toast-top-center',
                            timeOut: 3000
                        });
                    } else {
                        this._toastr.error('Login Failed.');
                    }
                });
        } else {
            this._fieldValid.validateAllFormFields(this.loginForm, this.loginFormError, this.validationMessages);
        }
    }

    onRegister(): void {
        this._router.navigate(['/register']);
    }
}
