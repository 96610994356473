//core modules

import { throwError as observableThrowError } from 'rxjs';

import { catchError } from 'rxjs/operators/catchError';

import { map } from 'rxjs/operators/map';

import { Injectable } from '@angular/core';

import { Headers, Http } from '@angular/http';

//custom modules
import { ErrorService, TokenService } from '../../shared/services';

import { Config } from '../../shared/constants/config';


@Injectable()
export class UserService {
  config = new Config();
  private access_token; url; url1; userData; USER_MANAGEMENT; GROUP_MANAGEMENT;
  private headers: Headers = new Headers();
  user_operations_api: any;
  subscription_url;

  constructor(
    private _http: Http,
    private _tokenService: TokenService,
    public _errorService: ErrorService
  ) {
    this.access_token = this._tokenService.getCurrentUserToken();
    this.url = this.config.USER_API;
    this.USER_MANAGEMENT = this.config.USER_MANAGEMENT;
    this.GROUP_MANAGEMENT = this.config.GROUP_MANAGEMENT;
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('x-access-token', 'Bearer ' + this.access_token);
  }

  getAllUsers(status, entity_id) {
    return this._http.get(`${this.url}/listUsers/${entity_id}`, { headers: this.headers }).pipe(map(res => res.json()));
  }

  /**
   * @description this method use to update the particular field data
   * @param details data which need to be get updated
   * @param user_id this is id of user
   */
  updateUser(details, userId) {
    console.log(details, userId);
    return this._http.put(`${this.url}/updateUser/${userId}`,
      { details: details }, { headers: this.headers }).pipe(
      map(res => res.json()),
      catchError((e: any) => observableThrowError(this._errorService.handleError(e))),);
  }

  // delete display
  deleteUser(userId, audit?) {
    return this._http.delete(`${this.url}/delete/${userId}?audit=${JSON.stringify(audit)}`, { headers: this.headers }).pipe(map(res => res.json()));
  }

  // getting the display by id
  getUser(userId) {
    return this._http.get(`${this.url}/details/${userId}`, { headers: this.headers }).pipe(
      map(res => res.json()),
      catchError((e: any) => observableThrowError(this._errorService.handleError(e))),);
  }

  addUser(details) {
    return this._http.post(`${this.url}/addUser`, details, { headers: this.headers }).pipe(
      map(res => res.json()),
      catchError((e: any) => observableThrowError(this._errorService.handleError(e))),);
  }

  changeStatus(userId, status) {
    return this._http.put(`${this.url}/changeStatus/${userId}/${status}`, { headers: this.headers }).pipe(map(res => res.json()));
  }

  updateRoles(data) {
    return this._http.put(`${this.USER_MANAGEMENT}/update`, data, { headers: this.headers }).pipe(
      map(res => res.json()),
      catchError((e: any) => observableThrowError(this._errorService.handleError(e))),);
  }
  fetchRoles(role, entity_id) {
    console.log(role, 'role in service');
    return this._http.get(`${this.USER_MANAGEMENT}/fetch/${role}/${entity_id}`, { headers: this.headers }).pipe(
      map(res => res.json()),
      catchError((e: any) => observableThrowError(this._errorService.handleError(e))),);
  }

  createGroup(data) {
    return this._http.post(`${this.GROUP_MANAGEMENT}/create`, data, { headers: this.headers }).pipe(
      map(res => res.json()),
      catchError((e: any) => observableThrowError(this._errorService.handleError(e))),);
  }
  fetchGroups(entity_id) {
    return this._http.get(`${this.GROUP_MANAGEMENT}/fetch/${entity_id}`, { headers: this.headers }).pipe(
      map(res => res.json()),
      catchError((e: any) => observableThrowError(this._errorService.handleError(e))),);
  }
  deleteGroup(groupId, audit?) {
    return this._http.delete(`${this.GROUP_MANAGEMENT}/delete/${groupId}?audit=${JSON.stringify(audit)}`, { headers: this.headers }).pipe(map(res => res.json()));
  }

}
