/**
 * @description These static messages are related to success response. List all the static messages & labels here.
 * @author raju.c@pickcel.com
 * @since 29-Jan-2018
 * e.g.,
 * SUCCESS : 'successfully registration'
 */

import { InjectionToken } from '@angular/core';

// by default public & static
export interface MessageConfig {
    SUCCESS: string;
}

export const MSG_DI_CONFIG: MessageConfig = {
    SUCCESS: 'successful'
};

export const MESSAGE_CONFIG = new InjectionToken<MessageConfig>('messages');
