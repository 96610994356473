/**
 * @description Error handelling part for all the services
 * @param error , which the server returns when any f the api is called
 * @method handleError
 * if the error code contains 401 it is unauthorized and taken back to login screen
 *
 */
import { Injectable, Inject, } from '@angular/core';
import { Observable } from 'rxjs';
import { Headers, Http, Response, HttpModule, URLSearchParams } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import 'rxjs';




import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from './token.service';
@Injectable()
export class ErrorService {
    public redirect_path;
    constructor(
        private _router: Router,
        private tokenService: TokenService
    ) {
        this.redirect_path = this._router.url;
    }

    public handleError(error) {
        console.log('There was an error', error);
        if (error.status === 422) {
            return error;
        } else if (error.status === 400) {
            return error;
        }
        if (error.json().error_code === 401) {
            this.tokenService.removeToken();
            this.tokenService.removeUserData();
            this._router.navigate(['/login']);
        } else {
            return error.json();
        }
    }
}
