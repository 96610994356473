/**
 * @description
 * @author raju.c@pickcel.com
 * @since 16-mar-2018
 */
//core modules
import { Component, OnInit } from '@angular/core';

//Form module
import { FormGroup, Validators, FormControl } from '@angular/forms';

//Routing module
import { ActivatedRoute, Router } from '@angular/router';

/*** Third party modules ****/
import { ToastrService } from 'ngx-toastr';

/******** Custom modules ******/
//custom services
import { Validation, CookieService, TokenService, LoginService } from '../shared/services';

//user model for login
import { User } from '../shared/services/user.model';

//Form validation logic
import { FieldValidationComponent } from '../shared/components/field-validation.component';

//server constant values 
import { Config } from '../shared/constants';

//environment file constal values
import { config } from '../../environments/environment';
import { PinkToast } from '../shared/constants/pink.toast';



@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [LoginService, Validation, FieldValidationComponent, TokenService, CookieService]
})

export class LoginComponent implements OnInit {
    //To hide/show password
    public type: string = 'password';
    
    //server configuration details
    private serverConfig: any = new Config();

    //login constant fields from environment file
    public loginEnvConfig = config.LOGIN;

    //Login form
    public loginForm: FormGroup;

    //Login button status
    public btnTriggered: boolean = false;

    //user model
    public user: User = new User();

    //Google Link for goole login user
    public googleLink: string = this.serverConfig.G_LOGIN;

    //Apple Link for user log in 
    public appleLink: string = this.serverConfig.APPLE_LOGIN;

    //Facebook Link for facebook login
    public fbLink: string = this.serverConfig.F_LOGIN;

    //Twitter link for twitter login 
    public twitterLink: string = this.serverConfig.TWITTER_LOGIN;

    //LinkedIn link for linked user
    public linkedinLink: string = this.serverConfig.LINKEDIN_LOGIN;

    //Form validation messages
    public validation = this._validationMsg.loginValidationMessages;

    //To hide/show loader
    public showLoader: boolean = true;

    //LoggedIn user details object
    private _userData: any = {};

    //Login Form Errow fields
    public loginFormError: any = {
        email: '',
        password: ''
    };

    //Login form validation messages
    public validationMessages: any = {
        email: {
            required: 'Email is required.',
            pattern: 'Please enter valid email.',
        },
        password: {
            required: 'Password is required.',
            minlength: 'Password must be 6 characters.'
        }
    };

    //Allowed domains for server If it has more than one domains for server
    public DOMAINS = [];

    private regtype: string = 'self';
    public regEmail: string = '';
    public message: string = '';

    _zohoSalesIQ: any;

    public isMobileLayout = this.isMobileAgent();
    mobileView = config.LOAD_MOBILE;

    constructor(
        private _router: Router,
        private _validationMsg: Validation,
        private _loginService: LoginService,
        private _tokenService: TokenService,
        private _toastr: ToastrService,
        private _fieldValid: FieldValidationComponent,
        private _activatedRoute: ActivatedRoute
    ) {
        this.showLoader = true;
    }

    ngOnInit(): void {
        //To show loader
        this.showLoader = true;

        if (this.mobileView && this.isMobileLayout) {
            this._router.navigate(['/unsupported-browser/login'])
        }

        //To check user LoggedIn or not
        if (this._tokenService.getCurrentUserToken()) {
            this._router.navigate(['/index']);

        } else {
            //To remove loader
            // this._router.navigate(['/index']);
            this.showLoader = false;
        }

        //To create login form
        this.createLoginForm();

        //To check Allowed domains
        if (config.ALLOWED_DOMAINS && config.ALLOWED_DOMAINS.length > 0) {
            this.DOMAINS = config.ALLOWED_DOMAINS;
        } else {
            this.DOMAINS = [config.SERVER_NAME];
        }

        // this.regtype = this._activatedRoute.snapshot.queryParamMap.get("regtype");
        this.regtype = this._activatedRoute.snapshot.paramMap.get('type');
        this.regEmail = this._activatedRoute.snapshot.paramMap.get('email');
        switch (this.regtype) {
            case 'self': this.message = `A verification mail has been sent to ${this.regEmail}. Please check your mail and click on the confirmation link to activate your account`;
                break;
            case 'email': this.message = `Your email id ${this.regEmail} is verified. Please login to proceed.`;
                break;
            case 'expired': this.message = 'Session expired';
                break;
            default: this.message = '';
                break;
        }
        // console.log("reg email and type", this.regEmail, this.regtype);
        this.options = this._toastr.toastrConfig;
    }

    /**
     * @description To create Login Form
     */
    createLoginForm(): void {
        this.loginForm = new FormGroup({
            email: new FormControl(this.regEmail != '' ? this.regEmail : '', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,8}$')]),
            password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        }, { updateOn: 'submit' });

        this.loginForm.valueChanges.subscribe((formValue) => {
            this._fieldValid.validate(formValue, this.loginForm, this.loginFormError, this.validationMessages);
        });
    }

    /**
     * @description To login or authentication
     */
    onLogin(): void {
        if (this.loginForm.valid) {
            this.loginForm.value.domain = window.location.hostname;
            this.btnTriggered = true;
            // if ($zoho) {
            //     $zoho.salesiq.reset();
            // }
            this._loginService.login(this.loginForm.value)
                .subscribe((loggedInUser) => {
                    if (loggedInUser) {
                        // this._toastr.success(loggedInUser.message, '', {
                        //     timeOut: 2000,
                        //     positionClass: 'toast-top-center'
                        // });
                        this.openPinkToast('SUCCESS', 'Login Successfull', '');
                        if (loggedInUser.userData.partner) {
                            let domainStatus = false;
                            if (this.DOMAINS && this.DOMAINS.length > 0 && this.DOMAINS.includes(window.location.hostname)) {
                                domainStatus = true;
                            }
                            if (loggedInUser.userData.partner.domain === window.location.hostname || window.location.hostname === 'localhost' || domainStatus) {
                                loggedInUser.userData.settings = loggedInUser.settings;
                                loggedInUser.userData.role = loggedInUser.menus.role;
                                loggedInUser.userData.loggedInType = "LOCAL";

                                if (loggedInUser.userData.subscription_method === "MANUAL") {
                                    loggedInUser.menus.menus = loggedInUser.menus.menus.filter(item => item.id != 'Plan');
                                }
                                this._tokenService.setUser(loggedInUser.userData, loggedInUser.menus);
                                this._tokenService.setCountryData('');
                                this._tokenService.setToken(loggedInUser.token);
                                // SET VALUE IN COOKIE FOR SALES IQ
                                let zohoSalesVal = {
                                    userName: loggedInUser.userData.username,
                                    email: loggedInUser.userData.email
                                };
                                this._tokenService.setSalesIQ(zohoSalesVal);

                                this._userData = JSON.parse(this._tokenService.getCurrentUserData());
                                this._zohoSalesIQ = JSON.parse(this._tokenService.getSalesIQ());
                                // if ($zoho && this._zohoSalesIQ) {

                                //     // $zoho.salesiq.ready = function () {

                                //     $zoho.salesiq.visitor.name(this._zohoSalesIQ.userName);
                                //     $zoho.salesiq.visitor.email(this._zohoSalesIQ.email);

                                //     // }
                                // }
                                if (loggedInUser.userData.messages) {
                                    this._tokenService.storeNotifications(loggedInUser.userData.messages);

                                }
                                localStorage.setItem('notification', '')
                                if (localStorage) {
                                    localStorage.setItem("zoho-email", loggedInUser.userData.email);
                                }
                                this._router.navigate(['/index']);


                            } else {
                                this.openPinkToast('ERROR', 'Login Failed.', ''); // this._toastr.error('Login Failed.');
                            }
                        } else {
                            this.openPinkToast('ERROR', 'Login Failed.', '');
                        }
                    }
                }, (err) => {
                    this.btnTriggered = false;
                    if (err.status === 422) {
                        /* this._toastr.error('Email or Password is wrong.', 'Login Failed', {
                            timeOut: 2000,
                            positionClass: 'toast-top-center'
                        }); */
                        this.openPinkToast('ERROR', 'Email or Password is wrong.', '');
                    } else if (err.status === 400) {
                        /* this._toastr.error('Please verify your email.', 'Login Failed', {
                            closeButton: false,
                            positionClass: 'toast-top-center',
                            timeOut: 3000
                        }); */
                        this.openPinkToast('ERROR', 'Please verify your email.', '');
                    } else {
                        this.openPinkToast('ERROR', 'Login Failed.', '');
                    }
                });
        } else {
            this._fieldValid.validateAllFormFields(this.loginForm, this.loginFormError, this.validationMessages);
        }
    }

    /**
     * @description To redirect to register Page
     */
    onRegister(): void {
        console.log('register function')
        this._router.navigate(['/register']);
    }

    isMobileAgent() {
        const agent = navigator.userAgent || navigator.vendor || (window as any).opera;
        // tslint:disable-next-line:max-line-length
        return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(agent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(agent.substr(0, 4)));
    }

    signInApple(){
        window.open(`https://appleid.apple.com/auth/authorize?response_type=code+id_token&client_id=com.pickcel&redirect_uri=${this.appleLink}&scope=name+email&response_mode=form_post`,'_self');
    }

    showPassword(val) {
        this.type = (val === 'password') ? 'text' : 'password';
    }


    options: any;
    openPinkToast(status, title, message) {
  
      const opt = JSON.parse(JSON.stringify(this.options));
      opt.toastComponent = PinkToast;
      opt.routeTo = '';
      opt.from = 'LOGIN';
      opt.timeOut = 6000;
      opt.tapToDismiss = false;
      opt.action = status;
      // console.log('reference of composnent',PinkToast);
      opt.toastClass = 'pinktoast';
      // const { message, title } = this.getMessage();
      const inserted = this._toastr.show(message, title, opt);
      // if (inserted && inserted.toastId) {
      //   this.lastInserted.push(inserted.toastId);
      // }
      return inserted;
    }
}
