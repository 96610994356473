import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

// let isDebugMode = environment.production ? false : true;    // Enabled in DEV while disabled in PROD. 
let isDebugMode = false;
const noLogs = () => null;

/**
  * @module LoggerService
  * @description
  * Console Log wrapper that can be disabled in production mode from here.
**/
@Injectable()
export class LoggerService {

  constructor(private route: ActivatedRoute,
    private router: Router) {
    
   /*  this.router.events.subscribe(url => {
      if (url instanceof NavigationEnd) {
        console.log('>>>>>>>>>>>>>>>', url)
      }
    }) */
    this.route.queryParams.subscribe(qp => {
      // Could be enabled through routing (...?useDebugMode=true shall enable the DEBUG mode.)
      if(qp.useDebugMode === 'true') {
        isDebugMode =  true;
      }
    })
  }

  get log() {
    return isDebugMode ? console.log.bind(console) : noLogs;
  }

  get info() {
    return isDebugMode ? console.info.bind(console) : noLogs;
  }

  get error() {
    // return isDebugMode ? console.error.bind(console) : noLogs;
    return console.error.bind(console);
  }

  get warn() {
    // return isDebugMode ? console.warn.bind(console) : noLogs;
    return console.warn.bind(console);
  }

  get trace() {
    return isDebugMode ? console.trace.bind(console) : noLogs;
  }

  time(arg: string) {
    // console.count(arg);
    if (isDebugMode) console.time();
  }

  timeEnd(arg: string) {
    // console.count(arg);
    if (isDebugMode) console.timeEnd();
  }

  table(...args: any[]) {
    // console.table([{name: "UDS", ECTC: 8},{name: "UDP", ECTC: 10}]);
    if (isDebugMode) console.table();
  }

  clear() {
    if (isDebugMode) console.clear();
  }
}