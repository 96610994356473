import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

//if (environment.production) {
enableProdMode();
//}

// platformBrowserDynamic().bootstrapModule(AppModule)
// .catch(err => console.log(err));

document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic().bootstrapModule(AppModule)
        .then(ref => {
            // To ensure NG destroys itself on hot reloads.
            if (window['ngRef']) {
                window['ngRef'].destroy();
            }
            window['ngRef'] = ref;
        })
        .catch(err => console.log(err));
});
