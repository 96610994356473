import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlaceholderService {

  // custom empty message
  private customEmptyMessage = '';

  // Common empty placeholder template
  private getCommonEmptyPlaceholderTemplate() {
    let commonEmptyPlaceholderTemplate = `
    <div class="empty-state1">
       <div class="placeholder">
          <div class="all-items-preview">
              <div class="text-placeholder">
                  <div class="first-row animated-background"></div>
                  <div class="second-row animated-background"></div>
              </div>
              <div class="text-placeholder">
                  <div class="first-row animated-background"></div>
                  <div class="second-row animated-background"></div>
              </div>
              <div class="text-placeholder">
                  <div class="first-row animated-background"></div>
                  <div class="second-row animated-background"></div>
              </div>
              <div class="action-placeholder">
                  <div class="action animated-background"></div>
                  <div class="action animated-background"></div>
              </div>
          </div>
          <p>${this.customEmptyMessage}</p>
     </div>
   </div>
    `;

    return commonEmptyPlaceholderTemplate;
  }


  /**
   * Returns messages for the provided category as per the format of ngx-datatable.
   * @param category Category of messages.
   */
  getMessagesForNgxDatatable(category: 'ASSET_LIST' | 'SCREEN_LIST' | 'SCHEDULE_LIST'
    | 'QUICKPLAY_LIST' | 'COMPOSITION_LIST' | 'COMPOSITION_LIST_POPUP' | 'APP_LIST_POPUP'
    | 'USER_LIST' | 'GROUPS_LIST' | 'MEDIA_REPORT' | 'UPTIME_REPORT' | 'USER_PROFILE') {

    let placeholder, totalMessage;

    switch (category) {
      case 'ASSET_LIST':
        this.customEmptyMessage = "No Media Found";
        totalMessage = 'Media(s)'; break;
      case 'SCREEN_LIST':
        this.customEmptyMessage = "No Screen Found";
        totalMessage = 'Screens'; break;
      case 'SCHEDULE_LIST':
        this.customEmptyMessage = "No Schedule Found";
        totalMessage = 'Schedule(s)'; break;
      case 'QUICKPLAY_LIST':
        this.customEmptyMessage = "No Quick Play Found";
        totalMessage = 'Quick play'; break;
      case 'COMPOSITION_LIST':
        this.customEmptyMessage = "No Composition Found";
        totalMessage = 'Compositions'; break;
      case 'COMPOSITION_LIST_POPUP':
        this.customEmptyMessage = "No composition found ! Go ahead and create a new one.";
        totalMessage = 'Compositions'; break;
      case 'APP_LIST_POPUP':
        this.customEmptyMessage = "No App Found";
        totalMessage = 'Apps'; break;
      case 'USER_LIST':
        this.customEmptyMessage = "No User Found";
        totalMessage = 'Users'; break;
      case 'GROUPS_LIST':
        this.customEmptyMessage = "No Group Found";
        totalMessage = 'Groups'; break;
      case 'MEDIA_REPORT':
        this.customEmptyMessage = "No Media Found";
        totalMessage = 'Media'; break;
      case 'UPTIME_REPORT':
        this.customEmptyMessage = "No Display Found";
        totalMessage = 'Displays'; break;
        case 'USER_PROFILE':
          this.customEmptyMessage = "No Profile Created.";
          totalMessage = 'Profiles'; break;
    }

    placeholder = this.getCommonEmptyPlaceholderTemplate();
    return { emptyMessage: placeholder, totalMessage };
  }
}
