import {config} from '.././../../environments/environment'
export class Config {
    SERVER_URL = config.SERVER_URL;

    URL = this.SERVER_URL + '/api/v3';
    USER_API = this.URL + '/users';
    SCREEN_API = this.URL + '/screens';
    CUSTOMER_API = this.URL + '/crms';
    SUBSCRIPTION_API = this.URL + '/subscriptions';
    INVOICE_API = this.URL + '/invoices';
    SCHEDULE_API = this.URL + '/schedules';
    MASTERS_API = this.URL + '/masters';
    COMPOSITION_API = this.URL + '/compositions';
    CAMPAIGN_API = this.URL + '/campaigns';
    PLAY_NOW_API = this.URL + '/playnowcompositions';
    ASSETS_API = this.URL + '/assets';
    APP_STORE_URL = this.URL + '/apps';
    ENTITY_URL = this.URL + '/entity';
    CLIENT_REST_API_ENDPOINT = this.URL + '/clients';
    G_LOGIN = this.USER_API + '/login/google';
    F_LOGIN = this.USER_API + '/login/facebook';
    TWITTER_LOGIN = this.USER_API + '/login/twitter';
    LINKEDIN_LOGIN = this.USER_API + '/login/linkedIn';
    entityStorageType = 'aws'; // pickcel
    REPORT_API = this.URL + '/reports';
    USER_MANAGEMENT = this.URL + '/roles';
    GROUP_MANAGEMENT = this.URL + '/groups';
    AUDITS_API = this.URL + '/audit';
    TAG_API = this.URL + '/tags';
    CUSTOM_FIELD = this.URL+'/tags'
    DEVICE_PROFILE_API = this.URL+'/app-profile'
    APPLE_LOGIN = this.USER_API + '/apple'
}

