import { Injectable } from "@angular/core";
/**
 * @description
 * @author raju.c@pickcel.com
 * @since 16-mar-2018
 */

@Injectable()
export class Validation {
    loginValidationMessages = {
        username: {
            required: 'Username is required.',
            pattern: 'Enter valid Username.'
        },
        password: {
            required: 'Password is required.',
            minlength: 'Password must be at least 4 characters.',
            maxlength: 'Password can\'t be longer than 15 characters.'
        }
    };
    constructor() { }
}
