import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutogrowDirective } from '../directives/autogrow.directive';
import { ScrollDirective } from '../directives/scroll.directive';
import { FilterPipe } from '../directives/filter.pipe';
import { SafeHtmlPipe } from '../directives/safe-html.pipe';
import { SafeUrlPipe } from '../directives/safe-url.pipe';
import { FileSizePipe1 } from '../directives/bytesToSize.pipe';
import { MilliSecondsPipe } from '../directives/millisecondsToTime.pipe';
import { MinuteSecondsPipe } from '../directives/secondsToMinSec.pipe';
import { HoursMinutesPipe } from '../directives/timeConversion.pipe';
import { FileSizePipe } from '../directives/bytesToHighestUnitConversion.pipe';
import { SecondsToHMSPipe } from '../directives/secondsToHMS.pipe';
import { DatepickerComponent } from '../components/datepicker/datepicker.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisableAfterClickDirective } from '../directives/disableAfterClickDirective';
import { LimitTextLengthPipe } from '../directives/limit-text-length.pipe';
import { InputfocusDirective } from '../directives/inputfocus.directive';
import { DisableOnClickDirective } from '../directives/disable-on-click.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule, 
        ReactiveFormsModule
    ],
    declarations: [
        AutogrowDirective,
        ScrollDirective,
        FilterPipe,
        FileSizePipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        MilliSecondsPipe,
        MinuteSecondsPipe,
        HoursMinutesPipe,
        FileSizePipe,
        FileSizePipe1,
        SecondsToHMSPipe,
        DatepickerComponent,
        DisableAfterClickDirective,
        LimitTextLengthPipe,
        InputfocusDirective,
        DisableOnClickDirective
    ],
    providers: [],
    exports: [
        AutogrowDirective,
        ScrollDirective,
        FilterPipe,
        FileSizePipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        MilliSecondsPipe,
        MinuteSecondsPipe,
        HoursMinutesPipe,
        SecondsToHMSPipe,
        DatepickerComponent,
        DisableAfterClickDirective,
        LimitTextLengthPipe,
        InputfocusDirective,
        DisableOnClickDirective,
        TranslateModule
    ]
})
export class CustomModule { }
