// import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { Headers, Http } from '@angular/http';
import { map } from 'rxjs/operators/map';
import { config } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PexelsService {
  private baseUrl = "https://api.pexels.com/";
  private headers = new Headers();
  constructor(
    private http: Http
  ) {
    this.headers.append('Authorization', config.PEXELS_KEY);
  }

  loadImages() {
    console.log('LOAD IMAGES')
    return this.http.get(`${this.baseUrl}v1/curated`, { headers: this.headers }).pipe(map((res: any) => {
      return res.json();
    }));
  }

  loadVideos() {
    console.log('LOAD VIDEOS')
    return this.http.get(`${this.baseUrl}videos`, { headers: this.headers })
      .pipe(map((res: any) => {
        console.log(res)
        return res.json();
      }));
  }

  searchForPhotos(query) {
    this.http.get(`${this.baseUrl}/search?`, { headers: this.headers })
      .subscribe(res => {
        console.log('RESULT', res)
      })
  }

  getPexelContent(category, page?, search?) {
    search === '' ? search = 'design' : search = search;
    let url = '';
    if (category === 'IMAGE') {
      url = search ? `${this.baseUrl}v1/search` : `${this.baseUrl}v1/curated`;
    } else {
      url = search ? `${this.baseUrl}v1/videos/search` : `${this.baseUrl}videos/popular`;
    }

    page = page || 1;
    let query_string = `page=${page}`;

    if (search) {
      const encodedSearchQuery = encodeURIComponent(search);
      query_string = `${query_string}&query=${encodedSearchQuery}`;
    }
    console.log(query_string);
    return this.http.get(`${url}?${query_string}`, { headers: this.headers })
      .pipe(map((res: any) => {
        console.log(res)
        return res.json();
      }));
  }
}
